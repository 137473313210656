import axios from "axios";
import { resolve } from "path";
import { initializeApp } from "firebase/app";
import { getMessaging, MessagePayload, onMessage } from "firebase/messaging";
const cache = caches.open("TECHAPPS-SSO");
const firebaseConfig = {
  projectId: "##fbProjectId##",
  messagingSenderId: "##messagingSenderId##",
  appId: "##fbAppId##",
  apiKey: "##fbApiKey##"
}
let userId = "";
const getClaims = (tokenType = 'access_token', ssotype = '##defaultSSOType##') => {
  return caches.open('TECHAPPS-SSO').then(cache => cache.match(`/idp/${ssotype}/${tokenType}`).then(t => t?.text()));
};
const getssoType = () => {
  return caches.open('TECHAPPS-SSO').then(cache => cache.match(`/idp/ssoType`).then(t => t?.text()));
};

const initMsgConfig = async () => {    
  const ssoType = await getssoType();
  const { name, email, userid } = JSON.parse(
    (await getClaims("claims",ssoType)) || "{}"
  );
  userId = userid;

}

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const onPushMessage = (cb: (arg0: MessagePayload) => void) => {
  if ('Notification' in window) {
    Notification.requestPermission().then(() => {
      console.log('User Now Has Permission for push notifications');
      onMessage(messaging, (payload) => {
        console.log('Push Message received. ', payload);
        if (cb) { cb(payload); }
      });
    }).catch((error) => {
      console.log('Error', error)
    });
  } else {
    console.log("This browser does not support system notifications");
    return;
  }
  
}

const getFromCache = (key: string): Promise<string> => {
  return cache
    .then(c => c.match(`/${key}`))
    .then(r => (r ? r.text() : ""));
};
const sendNotification = async (topic : string) => {
  console.log('time out ended');
  const fcmToken = await getFromCache('fcm-token');
  const payload = {"topic":topic,"token": fcmToken};
  return new Promise(function (resolve) {
    axios.post("/api/notification/"+ userId +"/subscriptions",payload , { headers: { "user-id": userId } }).then(function (response) {
      let data = null;
      if (response && response.data && response.data) {
        data = response.data;
        console.log("Subscription",data);
      }
      resolve(data);
    }).catch(function () {
      resolve(null);
    });
  });

}
const subscribe = async (topic: string) => {
    // get token from cache
    const fcmToken = await getFromCache('fcm-token');     

    const payload = {"topic":topic,"token": fcmToken};
    console.log("token created + ", fcmToken);
    if ('Notification' in window) {
        Notification.requestPermission().then(async () => {
          if((payload.token == null || payload.token == "" || payload.token == '' || payload.token == undefined) && navigator.serviceWorker.controller != null ){
            navigator.serviceWorker.controller.postMessage({messageType: 'missingFCMToken'});
            console.log('time out started');
          setTimeout(()=>{sendNotification(topic)}, 5000);
          } else {
            await sendNotification(topic);
          }
      }).catch((error) => {
        console.log('Error', error)
      });
    } else {
      console.log("This browser does not support system notifications");
      return;
    } 
    //payload.token = await getFromCache('fcm-token');
    
    
    // POST techapps-messaging-api/esd-platform/notifications/v1/:userid/subscriptions
    // { "topic": topic, "token": token }
}

const unSubscribe = (topic: string) => {
    // get token from cache
    // DELETE techapps-messaging-api/esd-platform/notifications/v1/:userid/subscriptions
    // { "topic": topic, "token": token }
}
const getMessage = (messageId: string) => {
    return new Promise(function (resolve) {
      axios.get("/api/notification/message/" + messageId, { headers: { "user-id": userId } }).then(function (response) {
        let data = null;
        if (response && response.data && response.data) {
          data = response.data;
          console.log("notiifcation message",data);
        }
        resolve(data);
      }).catch(function () {
        resolve(null);
      });
    });
}
export default {
  install: (app: any, options: any) => {
    initMsgConfig();
    // Add default subscriptions for direct messages
   // subscribe("<app-or-dir:pier>/<userid-lower-case:sraj2>");
    (window as any).TAMessaging = {
        subscribe,
        unSubscribe,
        getMessage,
        onPushMessage
    };
  }
}
