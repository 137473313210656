import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Layout from "@/components/Layout.vue";
import Login from "@/components/Login.vue";
import NotFound from "@/components/NotFound.vue";
import RenewSession from "@/components/RenewSession.vue";
import { configVars, Layout as LayoutType } from "@/plugins/configVars";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    component: Login
  },
  {
    path: "/login/callback",
    component: Login
  },
  {
    path: "/",
    redirect: "/riot"
  },
  {
    path: "/renew-session/azuread",
    component: RenewSession,
    meta: {requiresAuth: true, ssoType: 'azuread'}
  },
  {
    path: "/renew-session/okta",
    component: RenewSession,
    meta: {requiresAuth: true, ssoType: 'okta'}
  }
];
configVars.appLayouts.forEach((layout: LayoutType) => {
  routes.push({
    path: `/${layout.dir}/:module?/:recordid?`,
    component: Layout,
    meta: { ...{ requiresAuth: true }, ...layout }
  });
});
routes.push({
  path: "/:catchAll(.*)",
  component: NotFound
});

export const routerdata = {
  history: createWebHistory(process.env.baseUrl),
  routes
};

const router = createRouter(routerdata);
export default router;
