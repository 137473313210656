
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "Layout",
  props: {
    name: String
  },
  data() {
    return {
      appLayout: "",
      ssoType: "",
      showSessionExpiry: false,
      sessionExpiryWindow: null as (Window | null)
      //   query: '',
      //   fetch: '',
      //   action: ''
    };
  },
  computed: {
    readableSsoType(): string {
      return this.ssoType == "azuread" ? "Azure AD" : "Okta";
    },
    title(): string {
      const module: any = this.$route.params.module;
      return `${this.$route.meta.title || ""}${module ? ` ${this.capitalizeFirstLetter(module)}` : ""}`;
    }
  },
  methods: {
    loadScript(uri: string) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.async = true;
        script.addEventListener("load", () => {
          resolve("");
        });
        script.addEventListener("error", () => {
          reject(new Error(`Error loading ${uri}`));
        });
        script.src = uri;
        document.head.appendChild(script);
      });
    },
    capitalizeFirstLetter(str: string) {
      return str
        .split(" ")
        .map(r => r.charAt(0).toUpperCase() + r.slice(1))
        .join(" ");
    },
    renewSession() {
      this.sessionExpiryWindow = window.open(`/renew-session/${this.ssoType}`, '_blank');
    }
  },
  created: async function() {
    document.title = this.title;

    //polyfill added to mitigate an issue with chrome browser where some of the users are experiencing issues while clicking on dropdowns
    if (!('path' in Event.prototype)) {
      Object.defineProperty(Event.prototype, 'path', {
        get: Event.prototype.composedPath});
    }
    //Polyfill ends here

    const appName: any = this.$route.meta.appName;
    const baseControllerPath = `//${window.location.host}/${appName}/controller${this.$route.meta.controllerType || ''}`;
    const version = await axios.get(`${baseControllerPath}/version`);
    await this.loadScript(
      `${baseControllerPath}/app${
        version.data.hash ? ('.' + version.data.hash) : ''
      }.js?v=${version.data.version}&controller_url=${
        baseControllerPath.replace("//", "")
      }`
    );
    this.ssoType = (this.$route.meta.ssoType || this.$configVars.defaultSSOType) as string;
    navigator.serviceWorker.addEventListener('message', event => {
      if (event.data && event.data.messageType == 'sessionManagement' /*&& event.data.ssoType == this.ssoType*/) { // add this back with ITSMINT-4970
        if (event.data.message == 'sessionExpired') {
          this.showSessionExpiry = true;
          if (event.data.autoRenew) {
            this.renewSession();
          }
        } else if (event.data.message == 'sessionRenewed') {
          this.showSessionExpiry = false;
          this.sessionExpiryWindow?.close();
        }
      }
    });
  },
  beforeCreate: function() {
    console.log("maincontainer before-create");
  },
  mounted: function() {
    this.appLayout = `<${this.$route.meta.tag} style="height:100%; display:block" userid="${this.$auth?.claims?.userid}" module="${this.$route.params.module}" recordid="${this.$route.params.recordid}" action="${this.$route.query.action || ""}" fetch="${this.$route.query.fetch || ""}" query="${this.$route.query.query || ""}" from="portalhost" />`;
  }
});
