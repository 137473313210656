import { nextTick } from "vue"

let interactionId: string, previousWorkflowId: string

/**
 * This function continuously checks the DOM for any elements with the attributes:
 * data-auto-analytics, data-workflow-id, data-sub-workflow-id, and not data-analytics-on.
 * 
 * For each element found, it adds a listener to call TALogger to log the event to Splunk.
 * 
 * See logger.ts for more information on TALogger
 * 
 * Example HTML Usage:
 * <button @click="triggerAdvancedSearch" data-auto-analytics="click" data-workflow-id="advanced-search" data-sub-workflow-id="advanced-search-triggered">Search</button>
 */
const initializeAnalytics = () => {
  nextTick().then(() => {
    document
      .querySelectorAll(
        '[data-auto-analytics][data-workflow-id][data-sub-workflow-id]:not([data-analytics-on])'
      )
      .forEach(e => {
        let data = (e as HTMLElement).dataset
        if (data.autoAnalytics) {
          if (previousWorkflowId != data.workflowId) {
            interactionId = ''
          }
          previousWorkflowId = data.workflowId ? data.workflowId : ''
          e.addEventListener(data.autoAnalytics, () => {
            data = (e as HTMLElement).dataset
            interactionId = (window as any).TALogger.info(
              data.workflowId,
              data.subWorkflowId,
              interactionId
            )
          })
          e.setAttribute('data-analytics-on', 'true')
        }
      })
    setTimeout(initializeAnalytics, 5)
  })
}

export default {
  install: (app: any, options: any) => {
    setTimeout(initializeAnalytics, 5);
    (window as any).TAAnalytics = {
      endInteraction: (workflowId: string, subWorkflowId: string) => {
        (window as any).TALogger.info(
          workflowId,
          subWorkflowId,
          interactionId
        )
        interactionId = '';
      }
    }
  }
}