/* eslint-disable @typescript-eslint/no-explicit-any*/
import router from "@/router";
import axios from "axios";
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";

interface Claims {
  name: string;
  userid: string;
  email: string;
}

interface Auth {
  isAuthenticated: () => boolean;
  getToken: () => Promise<string>;
  claims: Claims;
}
interface Config {
  appLayouts: string;
  azureTenant: string;
  azureClientId: string;
  azureScope: string;
  oktaDomain: string;
  oktaScope: string;
  oktaClientId: string;
  defaultSSOType: string;
  redirectUri: string;
}

let config: Config;
const cache = caches.open("TECHAPPS-SSO"),
  claims = {} as Claims;

const getFromCache = (key: string, ssoType?: string): Promise<string> => {
  const type = ssoType || config.defaultSSOType;
  return cache
    .then(c => c.match(`/idp/${type}/${key}`))
    .then(r => (r ? r.text() : ""));
};
const getToken = (ssotype?: string): Promise<string> => {
  return getFromCache("id_token", ssotype);
};
const getExp = (ssotype?: string): Promise<number> => {
  return getFromCache("exp", ssotype).then(exp => parseInt(exp));
};
const getClaims = (ssotype?: string): Promise<Claims> => {
  return getFromCache("claims", ssotype).then(claims => JSON.parse(claims));
};

const isAuthenticated = (ssotype?: string): Promise<boolean> => {
  const tokenExists = getToken(ssotype).then(t => !!t);
  const notExpired = getExp(ssotype).then((exp) => (((exp||0) * 1000) - Number(new Date())) > 0);
  getClaims(ssotype).then(claims => {
    (window as any).addToMap('name', claims.name);
    (window as any).addToMap('email', claims.email);
    (window as any).addToMap('userid', claims.userid);
  });
  return Promise.all([tokenExists, notExpired]).then(response => response[0] && response[1]);
};
const addToCache = (key: string, value: string) => {
  return cache.then(c =>
    c.put(
      key,
      new Response(value, { headers: { "content-type": "text/plain" } })
    )
  );
};
const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
const validateLogin = async (path: string) => {
  const params = new Map<string, string>();
  path
    .replaceAll(`${config.redirectUri}?`, "")
    .split("&")
    .forEach(param => {
      const parts = param.split("=");
      params.set(parts[0], parts[1]);
    });
  const ssoType = decodeURIComponent(params.get("state") as string)?.split(
    "::"
  )[0];
  const state = decodeURIComponent(params.get("state") as string)?.split(
    "::"
  )[1];
  const clientId =
    ssoType === "azuread" ? config.azureClientId : config.oktaClientId;
  const scope = ssoType === "azuread" ? config.azureScope : config.oktaScope;
  let url;
  if (ssoType === "azuread") {
    url = `https://login.microsoftonline.com/${config.azureTenant}/oauth2/v2.0/token`;
  } else {
    url = `https://${config.oktaDomain}/v1/token`;
  }

  const resp = await axios.post(
    url,
    `client_id=${clientId}&scope=${scope}&code=${params.get(
      "code"
    )}&redirect_uri=${window.location.origin}${
      config.redirectUri
    }&grant_type=authorization_code&code_verifier=YTFjNjI1OWYzMzA3MTI4ZDY2Njg5M2RkNmVjNDE5YmEyZGRhOGYyM2IzNjdmZWFhMTQ1ODg3NDcxY2Nl`,
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
  );
  const tokenPayload = parseJwt(resp.data.id_token);
  resp.data.exp = tokenPayload.exp;
  let claims = {
    name: "",
    email: "",
    userid: ""
  };
  const appname = state.split('/')[1];
  if (ssoType === "azuread") {
    claims = {
      name: `${tokenPayload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"]} ${tokenPayload["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"]}`,
      email: tokenPayload.email,
      userid: tokenPayload.ntid ? tokenPayload.ntid : tokenPayload[`${appname.toLowerCase()}_usercode`]  
    };
  } else {
    claims = {
      name: `${tokenPayload["FirstName"]} ${tokenPayload["LastName"]}`,
      email: tokenPayload.EmailID,
      userid: tokenPayload.ntid
    };
  }
  resp.data.claims = JSON.stringify(claims);
  await Promise.all(
    [
      "id_token",
      "access_token",
      "expires_in",
      "refresh_token",
      "exp",
      "claims"
    ].map(p => addToCache(`/idp/${ssoType}/${p}`, resp.data[p]))
  );
  (window as any).addToMap('name', claims.name);
  (window as any).addToMap('email', claims.email);
  (window as any).addToMap('userid', claims.userid);
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
        messageType: 'sessionManagement',
        message: 'sessionRenewed',
        ssoType: ssoType
    })
  } else {
    console.log("Service Worker not found")
  }
  return state ?? "";
};
interface KeyValuePairs {
  [key: string]: string; // Index signature for string keys and string values
}
// Function to create key-value pairs from a string
const createKeyValuePairs = (inputString: string, pairDelimiter: string, keyValueDelimiter: string) => {
  const result: KeyValuePairs = {}; // Use the defined type
  
  // Split the input string into pairs
  const pairs = inputString.split(pairDelimiter);
  
  pairs.forEach(pair => {
      const [key, value] = pair.split(keyValueDelimiter);
      if (key) {
          result[key.trim()] = value?.trim();
      }
  });

  return result;
}
const routeHandler = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.path == "/login/callback") {
    //This condition is defined specifically for below two pattern of urls
    //https://techapps-uat.t-mobile.com/mast/#/knowledge-management/alarms?knowledgeId=&managerClassName=Nokia_LCR&amoClass=&alertName=undefined&configItem=undefined&marketCode=undefined&severity=undefined&amoName=undefined&vendor=undefined&technology=undefined
    //https://techapps-uat.t-mobile.com/mast/#/knowledge-management/alarms?knowledgeId=500256880
    if(to.query && to.query.state && to.query.state.indexOf("/#/") > -1 && to.query.state.indexOf("?") > -1){
      const {state} = to.query;
        const queryString = state.toString().split("?")[1];
        if(queryString != ''){
          const queryParams = createKeyValuePairs(queryString,"&","=");
          next({path: decodeURIComponent(await validateLogin(to.fullPath)), query: queryParams ,hash: ''});
        }else{
          next({path: decodeURIComponent(await validateLogin(to.fullPath)),hash: ''});
        }
    }
    else{
      next(decodeURIComponent(await validateLogin(to.fullPath)));
    }
  } else if (
    to.matched.some(r => r.meta.requiresAuth) &&
    !(await isAuthenticated(to.meta?.ssoType as string))
  ) {
    const ssoType: any = to.meta?.ssoType || config.defaultSSOType;
    next({
      path: "/login",
      query: {
        redirectPath: to.fullPath,
        ssoType: ssoType
      }
    });
  } else {
    const ssoType: any = to.meta?.ssoType || config.defaultSSOType;
    await addToCache(`/idp/ssoType`, ssoType);
    if (!claims.userid) {
      const { name, email, userid } = JSON.parse(
        (await getFromCache("claims", ssoType)) || "{}"
      );
      claims.name = name;
      claims.email = email;
      claims.userid = userid;
    }
    next();
  }
};

const $auth = {
  isAuthenticated,
  getToken,
  claims
};
export default {
  install: (app: any, options: any) => {
    config = options.config;
    if (options.router) {
      router.beforeEach(routeHandler);
    }
    app.config.globalProperties.$auth = $auth;
  }
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $auth: Auth;
  }
}
