
import { defineComponent } from "vue";
export default defineComponent({
  name: "Login",
  props: {
    name: String
  },
  data() {
    return {
      appLayout: "",
      idps: ["azuread", "okta"],
      autoLogin: false,
      redirectUri: "/login/callback",
      cacheLocation: "localStorage"
    };
  },
  created() {
    console.log("mounting");
    const type = localStorage.getItem("autoLogin");
    const tries = parseInt(localStorage.getItem("loginTries") ?? "0");
    if (this.idps.length == 1 || (type && tries < 2)) {
      this.autoLogin = true;
      this.login(type ?? this.idps[0], tries);
    }
  },
  mounted() {
    const authType: any = this.$route.query.ssoType || null;
    if (authType) {
      this.login(authType);
    } else {
      this.login(this.$configVars.defaultSSOType);
    }
  },
  methods: {
    async login(type: any, tries = 0) {
      console.log("Auto Login: ", this.autoLogin);
      if (this.autoLogin) {
        localStorage.setItem("autoLogin", type);
      } else {
        localStorage.removeItem("autoLogin");
      }
      localStorage.setItem("loginTries", (tries + 1).toString());
      let url = "";
      if (type === "azuread") {
        url = `https://login.microsoftonline.com/${this.$configVars.azureTenant}/oauth2/v2.0/authorize?client_id=${this.$configVars.azureClientId}`;
      } else {
        url = `https://${this.$configVars.oktaDomain}/v1/authorize?client_id=${this.$configVars.oktaClientId}`;
      }
      const scope =
        type === "azuread"
          ? this.$configVars.azureScope
          : this.$configVars.oktaScope;
      const redirectPath = this.$route.query.redirectPath?.toString() ?? "/";
      const state = `${type}::${encodeURIComponent(redirectPath)}`;
      const code = "ZeryBZK1gal0iwV-KCxfqTpp6lvIjY0OJk4x4O1rDu4";
      url += `&response_type=code&redirect_uri=${window.location.origin}${this.redirectUri}&response_mode=query&scope=${scope}&code_challenge=${code}&code_challenge_method=S256&state=${state}`;
      const claims = await caches.open("TECHAPPS-SSO").then(cache => cache.match(`/idp/${type}/claims`).then(t => t?.json()));
      if (claims && claims.userid && claims.userid.match(new RegExp(`^${this.$configVars.autoRefreshUsers}$`, 'i')) && claims.email) {
        url += `&login_hint=${claims.email}`;
      }

      window.location.href = url;
    },
    capitalize(value: string) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
});
