
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotFound",
  methods: {
    getAppName(str: string) {
      return str
        .slice(1)
        .split("/")[0]
        .split(" ")
        .map(r => r.charAt(0).toUpperCase() + r.slice(1))
        .join(" ");
    }
  },
  data() {
    return {
      requestedApp: ""
    };
  },
  mounted() {
    (this.$refs.esdLogo as any).style.backgroundImage = `url(${window.location.origin}/images/esd-logo.png)`;
    this.requestedApp = this.getAppName(this.$route.path);
  }
});
