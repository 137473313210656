import { v4 as uuid } from 'uuid-browser'

enum Level {
    DEBUG,
    INFO,
    ERROR
}
let logLevel = Level.INFO

const postMessage = (data: any) => {
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(data)
  } else {
    console.log('Service Worker not found')
  }
}

self.addEventListener('message', async event => {
  if (event.data && event.data.messageType == 'logLevel') {
    logLevel = Level[event.data.level as keyof typeof Level]
  }
}) 

postMessage({ messageType: 'logLevel' })

const log = (
  level: Level,
  workflowId: string,
  subWorkflowId: string,
  interactionId: string,
  message = ''
) => {
  try {
    if (!interactionId) {
      interactionId = uuid()
    }
    if (level >= logLevel) {
      fetch(new Request(`${window.location.origin}/log`, {
        method: 'POST',
        headers: {
            'level': Level[level],
            'workflow-id': workflowId,
            'sub-workflow-id': subWorkflowId,
            'interaction-id': interactionId,
            'activity-id': uuid(),
            'service-transaction-id': uuid(),
            'message': message  
        }
      }));
    }
    return interactionId
  } catch (error) {
    console.log(
      `Error in TALogger: level=${level} workflowId=${workflowId} subWorkflowId=${subWorkflowId} interactionId=${interactionId} message=${message} error=${error}`
    )
  }
}

export default {
  install: (app: any, options: any) => {
    /**
     * Used for logging a user action. Sends a message to the service worker which
     * calls an API to log the event in Splunk. If the info method is called, the
     * URL is also updated with a hash for the workflowId and subWorkflowId.
     *
     * By default, debug messages are not logged but can be turned on by push notification.
     *
     * Example Usage: window.TALogger.info('advanced-search', 'advanced-search-triggered');
     *
     * See analytics.ts for more information
     *
     * @param {string} workflowId business purpose, ex. advanced-search
     * @param {string} subWorkflowId user action, ex. advanced-search-triggered
     * @param {uuid} interactionId optional, uuid which represents this instance of the workflowId
     * @param {string} message optional, additional text for logging
     *
     * @returns {uuid} interactionId
     */
    (window as any).TALogger = {
      info: (
        workflowId: string,
        subWorkflowId: string,
        interactionId: string,
        message: string
      ) => {
        return log(Level.INFO, workflowId, subWorkflowId, interactionId, message)
      },
      error: (
        workflowId: string,
        subWorkflowId: string,
        interactionId: string,
        message: string
      ) => {
        return log(Level.ERROR, workflowId, subWorkflowId, interactionId, message)
      },
      debug: (
        workflowId: string,
        subWorkflowId: string,
        interactionId: string,
        message: string
      ) => {
        return log(Level.DEBUG, workflowId, subWorkflowId, interactionId, message)
      }
    }
  }
}