
import { defineComponent } from "vue";

export default defineComponent({
  name: "RenewSession",
  methods: {
    closeWindow() {
     // window.close();
    }
  },
  mounted() {
    (this.$refs.esdLogo as any).style.backgroundImage = `url(${window.location.origin}/images/esd-logo.png)`;
  }
});
