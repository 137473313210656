/* eslint-disable @typescript-eslint/no-explicit-any*/
export interface Layout {
  dir: string;
  tag: string;
  appName: string;
  title: string;
}
interface Config {
  appLayouts: Array<Layout>;
  azureTenant: string;
  azureScope: string;
  azureClientId: string;
  oktaScope: string;
  oktaDomain: string;
  oktaClientId: string;
  defaultSSOType: string;
  autoRefreshUsers: string;
}
export const configVars = (window as any)["techapps-config"]
  ? JSON.parse((window as any)["techapps-config"].innerHTML)
  : Object.assign({}, process.env);
if (typeof configVars.appLayouts == "string") {
  configVars.appLayouts = JSON.parse(configVars.appLayouts);
}
export default {
  install: (app: any) => {
    app.config.globalProperties.$configVars = configVars;
  }
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $configVars: Config;
  }
}
