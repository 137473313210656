import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import Authentication from '@/plugins/authentication';

import Messaging from "@/plugins/messaging";
import Logger from "@/plugins/logger";
import Analytics from "@/plugins/analytics";
import ConfigVars from '@/plugins/configVars';
import { configVars } from '@/plugins/configVars';
import './registerServiceWorker';

const authConfig = {
  config: {
    azureTenant:
      configVars.azureTenant || "be0f980b-dd99-4b19-bd7b-bc71a09b026c",
    oktaDomain: configVars.oktaDomain || "t-mobilestaging.oktapreview.com/oauth2/aus10fjt2p95wqVEs0h8",
    azureClientId:
      configVars.azureClientId || "4cef8c1c-7701-4fa7-827e-53943a0c5d11",
    azureScope:
      configVars.azureScope ||
      "openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fuser.read",
    oktaScope: configVars.oktaScope || "openid",
    oktaClientId: configVars.oktaClientId || "0oa10fjquxxUUztjz0h8",
    defaultSSOType: configVars.defaultSSOType || "azuread",
    redirectUri: "/login/callback",
    cacheLocation: "localStorage"
  },
  router: router
}

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(Authentication, authConfig)
  .use(ConfigVars)
  .use(Logger)
  .use(Analytics)
  .use(Messaging);
router.isReady().then(() => {
  if (navigator.serviceWorker.controller == null) {
    setTimeout(()=>{location.reload()}, 2000);
  }
  app.mount('#app');
});

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);